<template>


<div class="app-container">
    <div class="about-me">

      <div class="cb">
        <br>
        <br>
        <br>
        <hr>
        <h1>Connect Me in Social Network</h1>
        <a href="https://www.linkedin.com/in/zion-wong24/"> LINKEDIN </a>
        <a href="https://www.youtube.com/channel/UCK1EVyeYdV-c_DTNviA5_cA"> YOUTUBE </a>
        <a href="https://github.com/zionnoizy"> GITHUB </a>
      </div>

    </div>
    
  </div>
</template>
  

<style>
.app-container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.about-me {
  flex: 1;
  margin-right: 20px;
  color: white; /* Apply desired text color */
}

.footer {
  flex: 1;
  text-align: right;
}

.cb{
    clear:both;
}
</style>
  
  
<script>

</script>